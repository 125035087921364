.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.main-ring {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #ffffff57;
  backdrop-filter: blur(4px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999999999;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 5px solid transparent;
  border-radius: 50%;
  animation: lds-ring 1.5s cubic-bezier(0.24, 1.2, 0.83, 0.67) infinite;
  border-color: #8182ee transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.bg-hero_pattern {
  background-image: url(../public/img/Vector8.png);
  background-size: cover;
  /* mix-blend-mode: difference; */
}

.listener::after {
  content: "";
  height: 237px;
  width: 237px;
  background-color: #2A294D;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 99999px;
  z-index: -1;
}

#Slider-1 .slick-slide {

  padding-left: 10px;
  padding-right: 10px;
}

#Slider-1 .slick-list {
  padding: 0 5% 0 10% !important;
}

@media (max-width:1255px) {
  .Speciality {
    display: none;
  }
}

.BottomShape {
  background-image: url("./Components/assets/homepage/BottomShape.png");
  width: 100%;
  height: 704px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
}

@media (max-width:1024px) {
  .BottomShape {
    height: fit-content;
  }
}

.with::after {
  content: "";
  position: absolute;
  top: 0%;
  left: 13%;
  transform: translateX(-50%);
  background: url("./Components/assets/homepage/with.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  height: 100%;
  width: 134px;
}

.faqtransition {
  transition: all .35s;
}

.show {
  animation: bull .40s linear forwards;
}

@keyframes bull {
  0% {
    padding: 0;
  }

  100% {
    padding: 16px 16px 16px 32px;
  }
}

.imagesContainer {
  width: 55.5vw;
  height: 803px;
  position: relative;
  overflow: hidden;
}

@media (max-width:1444px) {
  .imagesContainer {
    width: 49.5vw;
  }
}

.imagesContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  animation: fadeInOut 20s infinite;
}

.imagesContainer img:nth-child(1) {
  animation-delay: 0s;
  /* Delay for the first image to start after the fourth */
}

.imagesContainer img:nth-child(2) {
  animation-delay: 5s;
  /* Start the animation for the fourth image immediately */
}

.imagesContainer img:nth-child(3) {
  animation-delay: 12s;
  /* Delay for the second image to start after the fourth */
}

.imagesContainer img:nth-child(4) {
  animation-delay: 18s;
}

@keyframes fadeInOut {

  0%,
  100% {
    opacity: 0;
  }

  25%,
  75% {
    opacity: 1;
  }
}

/* .imagesContainer {
  width: 852px;
  height: 803px;
  position: relative;
  overflow: hidden;
}

.imagesContainer img {
  width: 852px;
  position: absolute;
  top: 0;
  left: 0;
}

.fadeInClass {
  animation-name: fadeIn;
  animation-duration: 7s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: linear;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  20% {
    opacity: 0;
  }

  60% {
    opacity: 1;
  }

  100% {
    opacity: 1;
  }
} */