@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Allerta+Stencil&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;400;500;600&display=swap");

body {
  font-family: League Spartan;
}

::-webkit-scrollbar {
  width: 0px;
}

.revenue::after {
  content: "";
  position: absolute;
  bottom: -3.5px;
  left: 0;
  height: 50%;
  width: 100%;
  background-image: url("./Components/assets/dashboard/revenue.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.revenue::before {
  content: "";
  position: absolute;
  bottom: -3.5px;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, #2a2949 0%, rgba(42, 41, 73, 0.61) 100%);
}
.minutes::after {
  content: "";
  position: absolute;
  bottom: -3.5px;
  left: 0;
  height: 50%;
  width: 100%;
  background-image: url("./Components/assets/dashboard/minutes.png");
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

.minutes::before {
  content: "";
  position: absolute;
  bottom: -3.5px;
  left: 0;
  height: 50%;
  width: 100%;
  z-index: 1;
  background: linear-gradient(180deg, #2a2949 0%, rgba(42, 41, 73, 0.61) 100%);
}
